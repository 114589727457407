import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { graphql } from "gatsby"
import Layout from "../components/layout/Index"
import VerticalAds from "../components/commons/ads/vertical-ads/Index"
import ButtonBack from "../components/commons/page/buttonBack/index"
import { withTrans } from "../i18n/withTrans"

const Index = ({ data, location: { href } }) => {
  return (
    <Layout>
      <Container className="regulation-detail">
        <Row>
          <Col md={9} className="ps-0">
            <div className="d-flex align-items-start mb-4">
              <label className="title">{data?.wpRegulasi?.title}</label>
              {/* <div className="mt-2">
                <ButtonBack />
              </div> */}
            </div>
            <span className="primary-label">
              {data?.wpRegulasi?.regulasi?.tanggal}
            </span>
            <div
              dangerouslySetInnerHTML={{
                __html: data?.wpRegulasi?.regulasi?.deskripsi,
              }}
              className="content"
            />
            <iframe
              title="detail-kumpulan-peraturan"
              src={`https://docs.google.com/gview?url=${data?.wpRegulasi?.regulasi?.fileRegulasi?.mediaItemUrl}&embedded=true`}
              // style={{ width: "100%", height: 700 }}
              className="preview-file"
              frameborder="0"
            ></iframe>
            <div className="d-flex justify-content-between align-items-center mt-3">
              {data?.wpRegulasi?.regulasi?.fileRegulasi && (
                <a
                  download
                  target="_blank"
                  href={data.wpRegulasi.regulasi.fileRegulasi.mediaItemUrl}
                >
                  <Button className="custom-btn-primary">
                    Download (
                    {Number(
                      data.wpRegulasi.regulasi.fileRegulasi.fileSize / 1000000
                    ).toFixed(1)}
                    ) MB
                  </Button>
                </a>
              )}
            </div>
          </Col>
          <Col md={3} className="ads-parent">
            <VerticalAds width={280} height={360} title="B. BRONZE AD SPACE" />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    wpRegulasi(id: { eq: $id }) {
      id
      slug
      title
      regulasi {
        deskripsi
        fileRegulasi {
          mediaItemUrl
          fileSize
        }
        jenisRegulasi
        tanggal
      }
    }
  }
`

export default withTrans(Index)
